export const environment = {
  production: false,
  version: '2.0.0-dev',//change version when deploy new feature
  apiUrl: 'https://omni-channel-host.sandbox.public.rke.app.dev.tmtco.org',
  // apiUrl: 'https://localhost:44328',
  wiAccountUrl: 'https://account.public.rke.app.dev.tmtco.org',
  // wiAccountUrl: 'https://account.tmtco.org',
  aShipUrl: 'https://aship-v2.tpos.app',
  signalRHub: 'https://omni-channel-host.sandbox.public.rke.app.dev.tmtco.org/omni-hub',
  // signalRHub: 'https://localhost:44328/omni-hub'
};
